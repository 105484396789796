<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Monitor from "@/services/Monitor";
    import ModalAddNewTenantDashboard from  "@/components/modals/monitor/modalAddNewTenantDashboard";
    import ModalEditTenantDashboard from  "@/components/modals/monitor/modalEditTenantDashboard";
    import Swal from "sweetalert2";



    export default {
      components: { Layout, PageHeader, ModalAddNewTenantDashboard, ModalEditTenantDashboard },
      page: {
        title: "Dashboards",
        meta: [
          {
            name: "description",
            content: appConfig.description,
          },
        ],
      },
      data() {
        return {
          showLoader: false,
          error: null,
          filterData: false,
          filterDataAll: false,
          tableData: [],
          title: "Dashboards",
          items: [
            {
              text: "Dashboards",
              active: true,
              href: "/",
            },
          ],
          filterInput: {},
          totalRows: 0,
          currentPage: 1,
          perPage: 10,
          pageOptions: [10, 25, 50, 100],
          filter: null,
          filterOn: [],
          sortBy: "version",
          sortDesc: true,
          isTableLoading: false,
          tenantDashboardData: {},
          fields: [
            {
              key: "tenant",
              label: "Team ID",
              visible: false,
            },
            {
              key: "dashboard_url",
              label: "Dashboard URL",
              visible: true,
            },
            {
              key: "dashboard_version",
              label: "Dashboard Version",
              visible: true,
            },
            {
              key: "timestamp_updated",
              label: "Updated timestamp",
              visible: true,
            },
            "edit",
            "delete",
          ],
          totalRowsAll: 0,
        };
      },

      async mounted() {
        try {
          await this.getTenantDashboards();
        } catch (error) {
          console.error("Error during component initialization:", error);
        }
      },

      methods: {
        async getTenantDashboards() {
          this.showLoader = true;
          try {
            this.filterDataAll = true;
            this.isTableLoading = true;
            const response = await Monitor.getTenantDashboards();
            this.tableData = response.data;
            this.totalRowsAll = this.tableData.length;
          } catch (error) {
            this.error = error.response?.data?.error || "";
            this.tableData = [];
            this.totalRowsAll = 0;
          } finally {
            this.showLoader = false;
            this.isTableLoading = false;
          }
        },

        async deleteTenantDashboard(tenant) {
          try {
            const response = await Monitor.deleteTenantDashboard(tenant);
            if (response.data.delete) {
              this.getTenantDashboards();
              Swal.fire("Success", "Tenant Dashboard deleted successfully", "success");
            } else {
              Swal.fire("Error", "Failed to delete Tenant Dashboard", "error");
            }
          } catch (error) {
            Swal.fire("Error", "Failed to delete Tenant Dashboard", "error");
            console.error("Error deleting Tenant Dashboard:", error);
          }
        },

        async updateVersion(item, newVersion) {
          try {
            const payload = {
              tenant: item.tenant,
              dashboard_url: item.dashboard_url,
              dashboard_version: newVersion,
            };

            const response = await Monitor.updateVersion(payload);

            if (response.data) {
              if (newVersion === "N/A") {
                Swal.fire("Info", "Version is not updated", "info");
              } else {
                item.dashboard_version = newVersion;
                Swal.fire("Success", "Dashboard version updated successfully", "success");
              }
            } else {
              Swal.fire("Error", "Failed to update Dashboard version", "error");
            }
          } catch (error) {
            Swal.fire("Error", "An error occurred while updating the Dashboard version", "error");
          }
        },

        async refreshVersion(item) {
          this.$set(item, "isLoading", true);

          try {
            const dashboardUrl = item.dashboard_url;
            const iframe = document.getElementById("versionframe");
            iframe.src = dashboardUrl + "/version";

            // Use a promise to handle the iframe load event
            const elmnt = await new Promise((resolve, reject) => {
              iframe.onload = () => {
                try {
                  const content = iframe.contentWindow.document.getElementById("app");
                  if (content && content.innerText.trim()) {
                    resolve(content.innerText.trim());
                  } else {
                    resolve("N/A"); // Return "N/A" if content is empty
                  }
                } catch (err) {
                  reject(new Error("Failed to read content from iframe"));
                }
              };

              iframe.onerror = () => {
                reject(new Error("Failed to load iframe"));
              };

              setTimeout(() => {
                reject(new Error("Timeout: Failed to load dashboard version"));
              }, 3000);
            });

            item.dashboard_version = elmnt;

            await this.updateVersion(item, elmnt);
          } catch (error) {
            item.dashboard_version = "N/A"; // Set to "N/A" on error
            await this.updateVersion(item, "N/A"); // Call updateVersion with "N/A" on error
          } finally {
            this.$set(item, "isLoading", false);
          }
        },

        onFiltered(filteredItems) {
          this.totalRowsAll = filteredItems.length;
          this.currentPage = 1;
        },

        callModalEditTenantDashboard(item) {
          this.$bvModal.show("edit_tenant_dashboard");
          this.tenantDashboardData = { ...item };
        },

        callModalAddNewDashboard() {
          this.$bvModal.show("add_new_tenant_dashboard");
        },
      },

      middleware: "authentication",
    };

</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
      <iframe id="versionframe" style="display: none;"/>
     <div class="row" v-if="!showLoader">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <a  href="javascript:void(0);" class="btn btn-success waves-effect waves-light" @click="callModalAddNewDashboard">
                                        <i class="mdi mdi-plus me-2"></i>
                                        Add New
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>

                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-end"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :busy="isTableLoading"
                                    :items="tableData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    empty-text="No Data Found"
                                    @filtered="onFiltered"
                            >
                            <template v-slot:cell(dashboard_url)="data">
                                <a :href="data.item.dashboard_url" class="nav-link">
                                    <span>{{data.item.dashboard_url}}</span>
                                </a>
                            </template>
                              <template v-slot:cell(dashboard_version)="data">
                                <span v-if="!data.item.isLoading">{{ data.item.dashboard_version }}</span>
                                <span v-else>
                                  <b-spinner small></b-spinner>
                                </span>
                                <a href="javascript:void(0);" class="ms-2 text-primary" title="Refresh Version" @click="refreshVersion(data.item)">
                                  <i class="uil uil-refresh font-size-18"></i>
                                </a>
                              </template>

                              <template v-slot:cell(edit)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-primary"  title="Edit Tenant Dashboard" @click="callModalEditTenantDashboard(data.item)">
                                                <i class="uil uil-pen font-size-18"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </template>
                                <template v-slot:cell(delete)="data">
                             <a href="javascript:void(0);" class="px-2 text-danger" title="Remove Tenant Dashboard"
                         @click="deleteTenantDashboard(data.item.tenant)">
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
            </template>
            <template #table-busy>
                    <div class="text-center text-danger my-2">
                         <b-spinner class="align-middle"></b-spinner>
                             <strong>Loading...</strong>
                                    </div>
                                </template>
                            </b-table>
                         </div>
                        <div class="row">
                            <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-end">
                     <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="card-body d-flex align-items-center justify-content-center" >
                <b-spinner large></b-spinner>
            </div>
        </div>

        <!-- MODALS-->
        <ModalEditTenantDashboard :tenantDashboardData="tenantDashboardData" @onRefresh="getTenantDashboards()"></ModalEditTenantDashboard>
        <ModalAddNewTenantDashboard @onRefresh="getTenantDashboards()"></ModalAddNewTenantDashboard>
        <!--MODALS -->

    </Layout>
</template>

